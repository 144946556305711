* {
  font-family: 'Rajdhani', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Rajdhani', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100vh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin-bottom: 0px !important;
}

.ant-input-password {
  justify-content: center !important;
  align-items: center !important;
}

.ant-select-selector {
  border: none !important;
  width: 100%;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 1vw !important;
}

.ant-select-multiple .ant-select-selection-item {
  height: auto !important;
  line-height: auto !important;
  line-height: 2vw !important;
}

.ant-input-affix-wrapper>input.ant-input {
  font-size: 1vw !important;
}

.ant-radio-button-wrapper {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  box-sizing: border-box !important;
  border-radius: 16px !important;
  height: 1.7vw !important;
}

.ant-radio-button-wrapper:hover {
  background: #bba391 !important;
  color: white !important;
  height: 1.7vw !important;
}

.ant-radio-button-wrapper-checked {
  background: #bba391 !important;
  color: white !important;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: transparent !important;
}

/* importante per togliere il right border blue nel radio button */
.ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

/* importante per la checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #a1a2aa !important;
  /* width: 0.7vw !important;
  height: 0.7vw !important; */
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  display: table;
  /* width: 0.7vw !important;
  height: 0.7vw !important; */
  border-bottom: 2px solid #06c270 !important;
  border-right: 2px solid #06c270 !important;

  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  width: 0.7vw !important;
  height: 0.7vw !important;
} */
.ant-input-wrapper {
  border: none !important;
}

.ant-input-group {
  border: none !important;
  font-size: 1vw !important;
}

.ant-layout-sider {
  max-width: 24vw !important;
}

.ant-input {
  font-size: 1em !important;
  border: 0px solid #d9d9d9 !important;
}

.ant-input:focus,
.ant-input:hover {
  /* border: 0px solid #d9d9d9 !important; */
  box-shadow: none !important;
  border-right-color: transparent !important;
  border: 1px solid #d9d9d9 !important;
}

.ant-input-search-button {
  border: 0px solid #d9d9d9 !important;
}

.ant-input-lg {
  padding: 9.5px 11px !important;
}

.ant-input-number-input {
  height: auto !important;
}

.ant-input-number {
  font-size: 1.3vw !important;
  width: 5vw !important;
}